body {
    background-color: #dddee0;
}

.atlanta-bg {
    width: 100%;
    height: 100vh;
    background: url("https://firebasestorage.googleapis.com/v0/b/jdm-consultants-llc.appspot.com/o/atlanta.jpg?alt=media&token=569e7c96-f16a-48d2-8d19-9219f511245b");
    background-size: cover;
    background-position-y: bottom;
    background-position-x: center;
}